import { Component, AfterViewInit, EventEmitter, OnDestroy, Input, Output } from '@angular/core';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/table';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/code';
//import 'tinymce/plugins/print';
import { CommunicationService } from '../../services/communication.service';
import { EnumModel } from '../../../models/shared/enum-model';
declare var tinymce: any;

@Component({
    selector: 'app-tiny-editor',
    template: `<textarea id="{{ elementId }}">{{ text }}</textarea>`,
})
export class TinyEditorComponent implements AfterViewInit, OnDestroy {
    @Input() elementId: string;
    @Input() elementHeight = null;
    @Input() readonly = 0;
    @Input() statusBar = true;
    @Input() text: string;
    @Output() onEditorContentChange = new EventEmitter();
    public editor: any;
    public subscriptionVariables: any;
    public setContentSubscription: any;
    public print: any;
    private DEFAULT_HEIGHT = 350;

    constructor(private communicationService: CommunicationService) {
        this.subscriptionVariables = this.communicationService.passVariableToTinyMCE.subscribe(
            (response: EnumModel) => {
                tinymce.execCommand('mceInsertContent', false, response.id);
            }
        );
        this.setContentSubscription = this.communicationService.setTinyMCEContent.subscribe(
            (text: string) => {
                tinymce.execCommand('mceSetContent', false, text)
            }
        )
        this.setContentSubscription = this.communicationService.tinyMCEPrint.subscribe(
            () => {
                tinymce.execCommand('mcePrint')
            }
        )
    }

    ngAfterViewInit() {
        tinymce.init({
            selector: `#${this.elementId}`,
            license_key: 'gpl',
            branding: false,
            promotion: false,
            plugins: ['link', 'image', 'code', 'table'],
            height: this.elementHeight ? this.elementHeight : this.DEFAULT_HEIGHT,
            menubar: 'edit view insert format table',
            menu: {
                edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall | searchreplace' },
                view: {
                    title: 'View',
                    items: 'code',
                },
                insert: {
                    title: 'Insert',
                    items: 'image link',
                },
                format: {
                    title: 'Format',
                    items: 'bold italic underline strikethrough superscript subscript | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat',
                },
                table: { title: 'Table', items: 'inserttable | cell row column | tableprops deletetable' },
            },
            toolbar: false,
            statusbar: this.statusBar,
            readonly: this.readonly,
            skin_url: '/assets/css/skins/ui/oxide',
            content_css: '/assets/css/skins/content/default/content.min.css',
            setup: (editor) => {
                this.editor = editor;
                editor.on('keyup change', () => {
                    const content = editor.getContent();
                    this.onEditorContentChange.emit(content);
                });
                editor.on('BeforeSetContent', function (e) {
                    e.content = e.content.replace(/((color|background-color|border-color)\s*:\s*|background\s*:[^;]*?)\btransparent\b/g, '$1#00000000');
                });
            },
        });
    }

    ngOnDestroy() {
        tinymce.remove(this.editor);
        if (this.subscriptionVariables) {
            this.subscriptionVariables.unsubscribe();
        }
        if (this.setContentSubscription) {
            this.setContentSubscription.unsubscribe();
        }
    }
}
