<!--<app-header></app-header>-->
<div class="activation-wrapper flex" [ngStyle]="{ 'background-image': 'url(' + imageUrl + ')' }">
    <div class="custom-box" *ngIf="!linkValid">
        <div class="link-invalid">{{linkInvalidReason}}</div>
    </div>
    <div class="custom-box" *ngIf="linkValid">
        <div class="logo-img">
            <img src="../../../../assets/images/logo.svg" alt="caterbook logo" />
        </div>
        <h2>Set new password</h2>
        <p>Please enter a new password.</p>
        <form [formGroup]="valForm" (ngSubmit)="setNewPassUser($event)">
            <div class="form-item">
                <div class="custom-label">Password</div>
                <input
                    name="newPassword"
                    name="newPassword"
                    formControlName="newPassword"
                    [(ngModel)]="userPasswordModel.newPassword"
                    type="password"
                    class="custom-input"
                />
                <span
                    class="text-danger"
                    *ngIf="
                        valForm.controls['newPassword'].hasError('required') &&
                        (valForm.controls['newPassword'].dirty || valForm.controls['newPassword'].touched)
                    "
                    >Password is required.</span
                >
                <span
                    class="text-danger"
                    *ngIf="
                        valForm.controls['newPassword'].hasError('maxlength') &&
                        (valForm.controls['newPassword'].dirty || valForm.controls['newPassword'].touched)
                    "
                    >Max 50 characters.</span
                >
                <span
                    class="text-danger password-pattern"
                    *ngIf="valForm.controls['newPassword'].hasError('pattern') && valForm.controls['newPassword'].dirty"
                    >Password must contain at least 12 characters, including uppercase, lowercase letters and
                    number.</span
                >
            </div>
            <div class="form-item">
                <div class="custom-label">Confirm Password</div>
                <input
                    name="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    formControlName="confirmPassword"
                    [(ngModel)]="userPasswordModel.confirmPassword"
                    class="custom-input"
                />
                <span
                    class="text-danger"
                    *ngIf="
                        valForm.controls['confirmPassword'].hasError('required') &&
                        (valForm.controls['confirmPassword'].dirty || valForm.controls['confirmPassword'].touched)
                    "
                    >Confirm password is required.</span
                >
                <span
                    class="text-danger"
                    *ngIf="
                        valForm.controls['confirmPassword'].hasError('maxlength') &&
                        (valForm.controls['confirmPassword'].dirty || valForm.controls['confirmPassword'].touched)
                    "
                    >Max 50 characters.</span
                >
                <span
                    class="text-danger"
                    *ngIf="
                        valForm.controls['confirmPassword'].value != valForm.controls['newPassword'].value &&
                        !valForm.controls['confirmPassword'].hasError('required')
                    "
                    >Passwords do not match.</span
                >
            </div>
            <div class="actions flex">
                <button *ngIf="!loader" type="submit" class="custom-button blue">Confirm</button>
                <div *ngIf="loader" class="loader right"></div>
            </div>
        </form>
    </div>
</div>
