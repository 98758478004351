import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules, mapToCanActivate } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../layout/layout.component';
import { SideMenuComponent } from '../side-menu/side-menu.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ConfirmationComponent } from './signup/confirmation/confirmation.component';
import { ActivationComponent } from './signup/activation/activation.component';
import { RoomNumberComponent } from './signup/room-number/room-number.component';
import { RoomCreationComponent } from './signup/room-creation/room-creation.component';
import { RoomTypeComponent } from './signup/room-type/room-type.component';
import { AssignRoomsComponent } from './signup/assign-rooms/assign-rooms.component';
import { FunctionRoomsComponent } from './signup/function-rooms/function-rooms.component';
import { AccountDetailsComponent } from './login/account-details/account-details.component';
import { DebitMandateComponent } from './login/debit-mandate/debit-mandate.component';
import { PaymentDetailsComponent } from './login/payment-details/payment-details.component';
import { SettingsRoomTypesComponent } from './settings/settings-room-types/settings-room-types.component';
import { SettingsIncidentalSalesComponent } from './settings/settings-incidental-sales/settings-incidental-sales.component';
import { RatePlansComponent } from './settings/settings-pricing/rate-plans/rate-plans.component';
import { PricingPlansComponent } from './settings/settings-pricing/pricing-plans/pricing-plans.component';
import { RateSchedulesComponent } from './settings/settings-pricing/rate-schedules/rate-schedules.component';
import { PromoCodesComponent } from './settings/settings-pricing/promo-codes/promo-codes.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignupService } from './signup/signup.service';
import { AdminLoginComponent } from './admin-login/admin-login.component';

import { RoomTypeEditComponent } from './signup/room-type/room-type-edit/room-type-edit.component';
import {
    AuthGuardCompleteUser,
    AuthGuardInCompleteUser,
    AuthGuardNotSuperUser,
    AuthGuardUserByRole,
    UnsavedChangesGuard,
} from '../shared/auth-guards/auth-guard.service';
import { UpdateRoomComponent } from './signup/assign-rooms/update-room/update-room.component';
import { VirtualRoomComponent } from './signup/assign-rooms/virtual-room/virtual-room.component';
import { InventoryComponent } from './inventory/inventory.component';
import { RatePlansDetailsComponent } from './settings/settings-pricing/rate-plans/rate-plans-details/rate-plans-details.component';
import { SaleItemsPopupComponent } from './settings/settings-pricing/rate-plans/rate-plans-details/sale-items-popup/sale-items-popup.component';
import { CostFeesPopupComponent } from './settings/settings-pricing/rate-plans/rate-plans-details/cost-fees-popup/cost-fees-popup.component';
import { RatePlanService } from './settings/settings-pricing/rate-plans/rate-plan.service';
import { RatePlanSchedulesComponent } from './settings/settings-pricing/rate-plans/rate-plans-details/rate-plan-schedules/rate-plan-schedules.component';
import { EposAllowancePopupComponent } from './settings/settings-pricing/rate-plans/rate-plans-details/epos-allowance-popup/epos-allowance-popup.component';
import { RoomTypeAddComponent } from './signup/room-type/room-type-add/room-type-add.component';
import { RoomTypeImagesComponent } from './signup/room-type/room-type-images/room-type-images.component';
import { PricingPlansDetailsComponent } from './settings/settings-pricing/pricing-plans/pricing-plans-details/pricing-plans-details.component';
import { PricingPlanService } from './settings/settings-pricing/pricing-plans/pricing-plan.service';
import { AdminAccountsService } from './admin/admin-accounts/admin-accounts.service';
import { AuthLoginService } from '../shared/services/auth-login.service';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { SetNewPasswordComponent } from './login/set-new-password/set-new-password.component';
import { InventoryDetailsComponent } from './inventory/inventory-details/inventory-details.component';
import { InventoryPartnerComponent } from './inventory/inventory-partner/inventory-partner.component';
import { DeriveOptionsComponent } from './settings/settings-pricing/rate-plans/rate-plans-details/derive-options/derive-options.component';
import { CostCenterService } from './settings/settings-pricing/cost-centres/cost-center.service';
import { InventoryService } from './inventory/inventory.service';
import { SearchRoomTypePipe } from './inventory/search-room-type.pipe';
import { RateSchedulesDetailsComponent } from './settings/settings-pricing/rate-schedules/rate-schedules-details/rate-schedules-details.component';
import { RateSchedulesRatePlansComponent } from './settings/settings-pricing/rate-schedules/rate-schedules-rate-plans/rate-schedules-rate-plans.component';
import { RateSchedulesService } from './settings/settings-pricing/rate-schedules/rate-schedules.service';
import { MapRoomDetailsComponent } from './signup/assign-rooms/map-room-details/map-room-details.component';
import { PromoCodesService } from './settings/settings-pricing/promo-codes/promo-codes.service';
import { PromoCodeGenerateComponent } from './settings/settings-pricing/promo-codes/promo-code-generate/promo-code-generate.component';
import { AlertPopupComponent } from './settings/settings-pricing/rate-plans/rate-plans-details/alert-popup/alert-popup.component';
import { IncidentalSalesItemComponent } from './settings/settings-incidental-sales/incidental-sales-item/incidental-sales-item.component';
import { IncidentalSaleCategoryComponent } from './settings/settings-incidental-sales/incidental-sale-category/incidental-sale-category.component';
import { IncidentalSalesService } from './settings/settings-incidental-sales/incidental-sales.service';
import { AccountActivationComponent } from './login/account-activation/account-activation.component';
import { SuccessModalComponent } from './login/payment-details/success-modal/success-modal.component';
import { MessageModalComponent } from './login/debit-mandate/message-modal/message-modal.component';
import { HeaderComponent } from '../header/header.component';

import { SettingsHousekeepingComponent } from './settings/settings-housekeeping/settings-housekeeping.component';
import { SettingsVatRatesComponent } from './settings/settings-vat-rates/settings-vat-rates.component';
import { PassCodeComponent } from '../shared/components/pass-code/pass-code.component';
import { PoliciesService } from './policies/policies.service';
import { PromoCodeSaleItemsComponent } from './settings/settings-pricing/promo-codes/promo-code-generate/promo-code-sale-items/promo-code-sale-items.component';
import { DynamicPricingComponent } from './settings/settings-pricing/pricing-plans/dynamic-pricing/dynamic-pricing.component';
import { OccupancyRulesComponent } from './settings/settings-pricing/pricing-plans/occupancy-rules/occupancy-rules.component';
import { RateSchedulesRoomTypesComponent } from './settings/settings-pricing/rate-schedules/rate-schedules-room-types/rate-schedules-room-types.component';
import { RoomTypeRateScheduleComponent } from './signup/room-type/room-type-edit/room-type-rate-schedule/room-type-rate-schedule.component';
import { locale } from 'devextreme/localization';
import { ConfirmDirectDebitModalComponent } from './login/debit-mandate/confirm-direct-debit-modal/confirm-direct-debit-modal.component';
import { GuaranteeInfoComponent } from './login/debit-mandate/guarantee-info/guarantee-info.component';
import { HousekeepingService } from './settings/settings-housekeeping/housekeeping.service';
import { SettingsBillingComponent } from './settings/settings-billing/settings-billing.component';
import { ReportsService } from './reports/reports.service';
import { SettingsPaymentsComponent } from './settings/settings-payments/settings-payments.component';
import { RoomTypeOrderComponent } from './signup/room-type/room-type-order/room-type-order.component';
import { RatePlanOrderComponent } from './settings/settings-pricing/rate-plans/rate-plan-order/rate-plan-order.component';
import { RoomTypeResolver } from './signup/room-type/room-type-resolver.service';
import { GuestRegistrationServiceNew } from './guest-registration-new/guest-registration-new.service';
import { PciBookingService } from './pcibooking/pcibooking.service';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { SettingsBillingPdResolver } from './settings/settings-billing/settings-billing-resolver.service';
import { ClickOutsideDirective } from '../shared/directives/click-outside.directive';
import { OtaConnectionsService } from './settings/settings-ota-connections/ota-connections.service';
import { OtaMappingsService } from './settings/settings-ota-mappings/ota-mappings.service';
import { InternationalDiallingCodesService } from '../shared/services/international-dialling-codes.service';
import { CookieService } from 'ngx-cookie-service';
import { WidgetPaymentRedirectComponent } from './widget-payment-redirect/widget-payment-redirect.component';
import { OtaLogsService } from './settings/settings-ota-logs/ota-logs.service';
import { OtaSyncOutLogsService } from './settings/settings-ota-sync-out-logs/ota-sync-out-logs.service';
import { GuestRegistrationNewComponent } from './guest-registration-new/guest-registration-new.component';
import { TrimObjService } from '../shared/services/obj-trim.service';
import { CloseOutDetailsComponent } from './settings/settings-pricing/pricing-plans/close-out-details/close-out-details.component';
import { PermissionsService } from '../shared/services/permissions.service';
import { ChangeOrderHousekeepingComponent } from './settings/settings-housekeeping/change-order-housekeeping/change-order-housekeeping.component';
import { RoomOrderComponent } from './signup/assign-rooms/assign-room-order/assign-room-order.component';

import { SettingsGuestsComponent } from './settings/settings-guests/settings-guests.component';
import { SettingsAssignRoomsComponent } from './settings/settings-assign-rooms/settings-assign-rooms.component';
import { SettingsEmailsComponent } from './settings/settings-emails/settings-emails.component';
import { SettingsEmailsItemComponent } from './settings/settings-emails/settings-emails-item/settings-emails-item.component';
import { SettingsHousekeepingDetailsComponent } from './settings/settings-housekeeping/settings-housekeeping-details/settings-housekeeping-details.component';
import { SettingsHousekeepingGroupsComponent } from './settings/settings-housekeeping/settings-housekeeping-groups/settings-housekeeping-groups.component';
import { SettingsBillingInvoiceComponent } from './settings/settings-billing/settings-billing-invoice/settings-billing-invoice.component';
import { SettingsBillingInvoiceListComponent } from './settings/settings-billing/settings-billing-invoice-list/settings-billing-invoice-list.component';
import { SettingsAccountService } from './settings/settings-account/settings-account.service';
import { SettingsUsersService } from './settings/settings-users/settings-users.service';
import { SettingsProfileService } from './settings/settings-profile/settings-profile.service';
import { SettingsPropertiesService } from './settings/settings-properties/settings-properties.service';
import { SettingsPartnersService } from './settings/settings-partners/settings-partners.service';
import { SettingsVatRatesService } from './settings/settings-vat-rates/settings-vat-rates.service';
import { SettingsTemplatesService } from './settings/settings-templates/settings-templates.service';
import { SettingsBookingSourcesService } from './settings/settings-booking-sources/settings-booking-sources.service';
import { SettingsBillingService } from './settings/settings-billing/settings-billing-service';
import { SettingsPaymentsService } from './settings/settings-payments/settings-payments.service';
import { FunctionRoomsService } from './signup/function-rooms/function-rooms.service';
import { SharedModule } from '../shared/shared.module';
import { GuestBulkGRFComponent } from './booking/settings-guest-list/guest-bulk-GRF/guest-bulk-GRF.component';
import { BookingPrintReceiptComponent } from './booking/settings-booking-detail/booking-print-receipt/booking-print-receipt.component';
import {
    DxSelectBoxModule,
    DxTagBoxModule,
    DxPopoverModule,
    DxTemplateModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxSchedulerModule,
    DxTextBoxModule,
    DxCheckBoxModule,
    DxColorBoxModule,
    DxContextMenuModule,
    DxRadioGroupModule,
    DxSwitchModule,
    DxTooltipModule,
    DxPieChartModule,
    DxChartModule,
    DxDropDownBoxModule,
    DxAccordionModule,
    DxTextAreaModule,
    DxTreeListModule,
} from 'devextreme-angular';
import { FunctionRoomResolver } from './signup/function-rooms/function-rooms-resolver.service';
import { FunctionRoomsAddComponent } from './signup/function-rooms/function-rooms-add/function-rooms-add.component';
import { FunctionRoomsEditComponent } from './signup/function-rooms/function-rooms-edit/function-rooms-edit.component';
import { FunctionRoomOrderComponent } from './signup/function-rooms/function-room-order/function-room-order.component';
import { InventoryFunctionDetailsComponent } from './inventory/inventory-function-details/inventory-function-details.component';
import { SettingsXeroComponent } from './settings/settings-xero/settings-xero.component';
import { SettingsZReadComponent } from './settings/settings-zread/settings-zread.component';
import { XeroService } from './settings/settings-xero/xero-service';
import { GenericErrorComponent } from './error/generic-error/generic-error.component';

const cultureId = navigator.language;
locale(cultureId);

const appRoutes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            // dashboard
            {
                path: 'dashboard',
                canActivate: mapToCanActivate([AuthGuardUserByRole]),
                data: { roles: ['Owner', 'Manager', 'Supervisor', 'Receptionist', 'BookingOnly'] },
                loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
            },

            // booking
            {
                path: 'booking',
                loadChildren: () => import('./booking/booking.module').then((m) => m.BookingModule),
            },
            // function
            {
                path: 'function',
                loadChildren: () => import('./function/function.module').then((m) => m.FunctionModule),
            },
            // pricing
            {
                path: 'pricing/price-plans/:rateScheduleId/:ratePlanId',
                canActivate: mapToCanActivate([AuthGuardUserByRole]),
                data: { roles: ['Owner', 'Manager', 'Supervisor', 'Receptionist', 'BookingOnly'] },
                component: PricingPlansComponent,
            },
            {
                path: 'pricing/price-plans',
                canActivate: mapToCanActivate([AuthGuardUserByRole]),
                data: { roles: ['Owner', 'Manager', 'Supervisor', 'Receptionist', 'BookingOnly'] },
                component: PricingPlansComponent,
            },
            {
                path: 'pricing/rate-plans',
                canActivate: mapToCanActivate([AuthGuardUserByRole]),
                data: { roles: ['Owner', 'Manager', 'Supervisor', 'Receptionist', 'BookingOnly'] },
                component: RatePlansComponent,
            },
            {
                path: 'pricing/extras',
                canActivate: mapToCanActivate([AuthGuardUserByRole]),
                data: { roles: ['Owner', 'Manager', 'Supervisor', 'Receptionist', 'BookingOnly'] },
                component: SettingsIncidentalSalesComponent,
            },
            {
                path: 'pricing/promo-codes',
                canActivate: mapToCanActivate([AuthGuardUserByRole]),
                data: { roles: ['Owner', 'Manager', 'Supervisor', 'Receptionist', 'BookingOnly'] },
                component: PromoCodesComponent,
            },
            {
                path: 'pricing/rate-schedules',
                canActivate: mapToCanActivate([AuthGuardUserByRole]),
                data: { roles: ['Owner', 'Manager', 'Supervisor', 'Receptionist', 'BookingOnly'] },
                component: RateSchedulesComponent,
            },

            // inventory
            {
                path: 'inventory/availability',
                canActivate: mapToCanActivate([AuthGuardUserByRole]),
                data: { roles: ['Owner', 'Manager', 'Supervisor', 'Receptionist', 'BookingOnly'] },
                component: InventoryComponent,
            },
            {
                path: 'inventory/room-types',
                canActivate: mapToCanActivate([AuthGuardUserByRole]),
                data: { roles: ['Owner', 'Manager', 'Supervisor', 'Receptionist', 'BookingOnly'] },
                component: SettingsRoomTypesComponent,
                resolve: { roomTypesModel: RoomTypeResolver },
            },
            {
                path: 'inventory/function-rooms',
                canActivate: mapToCanActivate([AuthGuardUserByRole]),
                data: { roles: ['Owner', 'Manager', 'Supervisor', 'Receptionist', 'BookingOnly'] },
                component: FunctionRoomsComponent,
                resolve: { functionRoomsModel: FunctionRoomResolver },
            },
            {
                path: 'inventory/assign-rooms',
                canActivate: mapToCanActivate([AuthGuardUserByRole]),
                data: { roles: ['Owner', 'Manager', 'Supervisor', 'Receptionist', 'BookingOnly'] },
                canDeactivate: [UnsavedChangesGuard],
                component: AssignRoomsComponent,
            },
            {
                path: 'inventory/housekeeping',
                canActivate: mapToCanActivate([AuthGuardUserByRole]),
                data: { roles: ['Owner', 'Manager', 'Supervisor', 'Receptionist', 'BookingOnly', 'Housekeeping'] },
                component: SettingsHousekeepingComponent,
            },

            // finance
            {
                path: 'finance/billing',
                canActivate: mapToCanActivate([AuthGuardUserByRole]),
                data: { roles: ['Owner', 'Manager', 'Supervisor', 'Receptionist', 'BookingOnly'] },
                component: SettingsBillingComponent,
                resolve: { pdData: SettingsBillingPdResolver },
            },
            {
                path: 'finance/payments',
                canActivate: mapToCanActivate([AuthGuardUserByRole]),
                data: { roles: ['Owner', 'Manager', 'Supervisor', 'Receptionist', 'BookingOnly'] },
                component: SettingsPaymentsComponent,
            },
            {
                path: 'finance/vat-rates',
                canActivate: mapToCanActivate([AuthGuardUserByRole]),
                data: { roles: ['Owner', 'Manager', 'Supervisor', 'Receptionist', 'BookingOnly'] },
                component: SettingsVatRatesComponent,
            },
            {
                path: 'finance/zread',
                canActivate: mapToCanActivate([AuthGuardUserByRole]),
                data: { roles: ['Owner', 'Manager', 'Supervisor'] },
                component: SettingsZReadComponent,
            },
            {
                path: 'finance/xero',
                canActivate: mapToCanActivate([AuthGuardUserByRole]),
                data: { roles: ['Owner', 'Manager'] },
                component: SettingsXeroComponent,
            },
            {
                path: 'messaging-centre',
                loadChildren: () => import('./message-centre/message-centre.module').then((m) => m.MessageCentreModule),
            },
            {
                path: 'reports',
                loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
            },
            {
                path: 'settings',
                loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
            },

            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
        ],
    },

    { path: 'print-receipt', component: BookingPrintReceiptComponent },
    { path: 'print-booking-summary', component: BookingPrintReceiptComponent },
    { path: 'login', component: LoginComponent },
    { path: 'error', component: GenericErrorComponent },
    { path: 'login/activation', component: AccountActivationComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'forgot-password/expired', component: ForgotPasswordComponent },
    { path: 'ConfirmPassword', component: SetNewPasswordComponent },
    // { path: 'admin-login/:propertyId/:endUserId/:superUserId', component: AdminLoginComponent },
    { path: 'admin-login/:userId', component: AdminLoginComponent },
    { path: 'signup', component: SignupComponent },
    { path: 'confirmation', component: ConfirmationComponent },
    { path: 'register-password/:hash', component: ActivationComponent },
    { path: 'register-password', component: ActivationComponent },
    { path: 'room-number', canActivate: mapToCanActivate([AuthGuardInCompleteUser]), component: RoomNumberComponent },
    { path: 'room-creation/:numberOfRooms', canActivate: mapToCanActivate([AuthGuardCompleteUser]), component: RoomCreationComponent },
    { path: 'account-details', canActivate: mapToCanActivate([AuthGuardCompleteUser]), component: AccountDetailsComponent },
    { path: 'debit-mandate', canActivate: mapToCanActivate([AuthGuardCompleteUser]), component: DebitMandateComponent },
    { path: 'payment-details', canActivate: mapToCanActivate([AuthGuardCompleteUser]), component: PaymentDetailsComponent },
    { path: 'guest-registration', component: GuestRegistrationNewComponent },
    {
        path: 'messaging-centre/guest-bulk-grf',
        canActivate: mapToCanActivate([AuthGuardUserByRole]),
        data: { roles: ['Owner', 'Manager'] },
        component: GuestBulkGRFComponent,
    },

    { path: 'payment-success', component: PaymentSuccessComponent },
    { path: 'auth-success', loadChildren: () => import('./auth-success/auth-success.module').then((m) => m.AuthSuccessModule),},
    { path: 'widgetpaymentredirect', component: WidgetPaymentRedirectComponent },
    {
        path: 'paymentredirect',
        loadChildren: () => import('./payment-redirect/payment-redirect.module').then((m) => m.PaymentRedirectModule),
    },
    { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
    declarations: [
        HeaderComponent,
        LayoutComponent,
        SideMenuComponent,
        LoginComponent,
        SignupComponent,
        ConfirmationComponent,
        ActivationComponent,
        RoomNumberComponent,
        RoomCreationComponent,
        SettingsIncidentalSalesComponent,
        AdminLoginComponent,
        SettingsHousekeepingComponent,
        SettingsRoomTypesComponent,
        SettingsBillingComponent,
        SettingsPaymentsComponent,
        SettingsVatRatesComponent,
        SettingsGuestsComponent,
        SettingsAssignRoomsComponent,
        SettingsEmailsComponent,
        SettingsEmailsItemComponent,
        SettingsHousekeepingDetailsComponent,
        SettingsHousekeepingGroupsComponent,
        SettingsBillingInvoiceComponent,
        SettingsBillingInvoiceListComponent,

        RoomTypeComponent,
        AssignRoomsComponent,
        AccountDetailsComponent,
        DebitMandateComponent,
        PaymentDetailsComponent,
        RatePlansComponent,
        PricingPlansComponent,
        RateSchedulesComponent,
        PromoCodesComponent,
        RoomTypeEditComponent,
        UpdateRoomComponent,
        VirtualRoomComponent,
        InventoryComponent,
        RatePlansDetailsComponent,
        SaleItemsPopupComponent,
        CostFeesPopupComponent,
        RatePlanSchedulesComponent,
        EposAllowancePopupComponent,
        RoomTypeAddComponent,
        RoomTypeImagesComponent,
        PricingPlansDetailsComponent,
        ForgotPasswordComponent,
        SetNewPasswordComponent,
        InventoryDetailsComponent,
        InventoryPartnerComponent,
        DeriveOptionsComponent,
        SearchRoomTypePipe,
        RateSchedulesDetailsComponent,
        RateSchedulesRatePlansComponent,
        MapRoomDetailsComponent,
        PromoCodeGenerateComponent,
        AlertPopupComponent,
        IncidentalSalesItemComponent,
        IncidentalSaleCategoryComponent,
        AccountActivationComponent,
        //WysiwygDirective,
        SuccessModalComponent,
        MessageModalComponent,
        PassCodeComponent,
        PromoCodeSaleItemsComponent,
        DynamicPricingComponent,
        OccupancyRulesComponent,
        RateSchedulesRoomTypesComponent,
        RoomTypeRateScheduleComponent,
        ConfirmDirectDebitModalComponent,
        GuaranteeInfoComponent,
        RoomTypeOrderComponent,
        RatePlanOrderComponent,
        // GuestRegistrationComponent,
        // GuestRegistrationConfirmComponent,
        PaymentSuccessComponent,
        ClickOutsideDirective,
        WidgetPaymentRedirectComponent,
        GuestRegistrationNewComponent,
        CloseOutDetailsComponent,
        ChangeOrderHousekeepingComponent,
        RoomOrderComponent,
        GuestBulkGRFComponent,
        FunctionRoomsComponent,
        FunctionRoomsAddComponent,
        FunctionRoomsEditComponent,
        FunctionRoomOrderComponent,
        InventoryFunctionDetailsComponent,
        GenericErrorComponent
    ],
    imports: [
        RouterModule.forRoot(appRoutes, {
    preloadingStrategy: PreloadAllModules
}),
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        DxSelectBoxModule,
        DxTagBoxModule,
        DxPopoverModule,
        DxTemplateModule,
        FormsModule,
        DxDataGridModule,
        DxDateBoxModule,
        DxSchedulerModule,
        DxTextBoxModule,
        DxCheckBoxModule,
        DxColorBoxModule,
        DxContextMenuModule,
        DxRadioGroupModule,
        DxSwitchModule,
        DxTooltipModule,
        DxDropDownBoxModule,
        DxAccordionModule,
        DxTextAreaModule,
        DxTreeListModule,
        DxPieChartModule,
        DxChartModule,
    ],
    exports: [RouterModule],
    providers: [
        AuthLoginService,
        AuthGuardNotSuperUser,
        SignupService,
        RateSchedulesService,
        InventoryService,
        PricingPlanService,
        RatePlanService,
        CostCenterService,
        IncidentalSalesService,
        SideMenuComponent,
        AdminAccountsService,
        PromoCodesService,
        PoliciesService,
        HousekeepingService,
        ReportsService,
        RoomTypeResolver,
        GuestRegistrationServiceNew,
        PciBookingService,
        OtaConnectionsService,
        OtaMappingsService,
        InternationalDiallingCodesService,
        CookieService,
        OtaLogsService,
        OtaSyncOutLogsService,
        TrimObjService,
        PermissionsService,
        SettingsAccountService,
        SettingsUsersService,
        SettingsProfileService,
        SettingsPropertiesService,
        SettingsPartnersService,
        SettingsVatRatesService,
        SettingsTemplatesService,
        SettingsBookingSourcesService,
        SettingsBillingService,
        SettingsPaymentsService,
        FunctionRoomsService,
        FunctionRoomResolver,
        XeroService
    ],
})
export class RoutesModule {}

// platformBrowserDynamic().bootstrapModule(RoutesModule);
