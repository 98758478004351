import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserPasswordModel } from '../../../models/user/user-password.model';
import { AuthLoginService } from '../../../shared/services/auth-login.service';
import { ActionResultModel } from 'src/app/models/shared/action-result.model';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastEventType } from 'src/app/enums/toast-event-type';

@Component({
    selector: 'app-set-new-password',
    templateUrl: './set-new-password.component.html',
    styleUrls: ['./set-new-password.component.less'],
})
export class SetNewPasswordComponent implements OnInit {
    loader = false;
    imageUrl: any;
    //useful for testing: https://regex101.com/
    patternPassword = /^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{12,36}$/;

    valForm: UntypedFormGroup;
    userPasswordModel = new UserPasswordModel();
    linkValid = true;
    linkInvalidReason = '';

    constructor(
        private fb: UntypedFormBuilder,
        private route: ActivatedRoute,
        private authLoginService: AuthLoginService,
        private router: Router,
        private toasterService: ToastService
    ) {
        this.valForm = this.fb.group({
            newPassword: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(50),
                    Validators.pattern(this.patternPassword),
                ]),
            ],
            confirmPassword: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
        });
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params: Params) => {

            this.userPasswordModel.confirmHash = params['h'];
            this.userPasswordModel.check = params['c'];
            this.userPasswordModel.time = params['t'];

            this.loader = true;
            this.authLoginService.forgotPasswordCheck(this.userPasswordModel).subscribe(
                (response) => {
                    let result: ActionResultModel = response;
                    if (result.actionResult != "Success") {
                        this.linkValid = false;
                        this.linkInvalidReason = result.errors[0];
                    }
                    this.loader = false;
                },
                (error) => {
                    console.log(error);
                    this.loader = false;
                }
            );

        });
    }

    setNewPassUser(e) {
        e.stopPropagation();
        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (
            this.valForm.valid &&
            this.valForm.controls['newPassword'].value === this.valForm.controls['confirmPassword'].value
        ) {
            this.loader = true;

            this.authLoginService.setNewPassword(this.userPasswordModel).subscribe(
                (response) => {
                    let result: ActionResultModel = response;
                    if (response.actionResult == "Success") {
                        this.toasterService.showToast('Success', 'Password successfully changed.', null, ToastEventType.Success, true, 5000);
                        this.router.navigate(['login']);
                    }
                    else {
                        this.linkValid = false;
                        this.linkInvalidReason = result.errors[0];
                    }
                    this.loader = false;
                },
                (error) => {
                    console.log(error);
                    this.loader = false;
                }
            );
        }
    }
}
