import { Injectable } from '@angular/core';
import { HttpRequestService } from './http-request.service';
import { environment } from '../../../environments/environment';
import { UserPasswordModel } from '../../models/user/user-password.model';
import { ActivationFlowModel } from '../../models/user/activation.model';

@Injectable()
export class AuthLoginService {
    private forgotPasswordUrl = environment.apiUrl + '/User/ForgotPassword';
    private forgotPasswordCheckUrl = environment.apiUrl + '/User/ForgotPasswordCheck';
    private forgotPasswordAdminUrl = environment.apiUrl + '/AdminUser/ForgotPassword';
    private setNewPasswordUrl = environment.apiUrl + '/User/SetNewPassword';
    private setNewAdminPasswordUrl = environment.apiUrl + '/AdminUser/SetNewPassword';
    private createPropertyLoginTokenUrl = environment.apiUrl + '/User/CreatePropertyLoginToken';
    private getForActivationUrl = environment.apiUrl + '/User/GetForActivation';
    private activatePropertyUrl = environment.apiUrl + '/User/ActivateProperty';
    private getPropertyThumbnailUrl = environment.apiUrl + '/Property/GetPropertyThumbnail';
    private getPropertyInvoiceLogoBase64Url = environment.apiUrl + '/Property/GetPropertyInvoiceLogoBase64';

    constructor(private http: HttpRequestService) {}

    forgotPassword(account: string, username: string) {
        return this.http.get(this.forgotPasswordUrl + '?account=' + account + '&username=' + username);
    }

    forgotPasswordCheck(model: UserPasswordModel) {
        return this.http.get(this.forgotPasswordCheckUrl + '?h=' + model.confirmHash + '&c=' + model.check + '&t=' + model.time);
    }

    forgotPasswordAdmin(email: string) {
        return this.http.get(this.forgotPasswordAdminUrl + '?email=' + email);
    }

    setNewPassword(model: UserPasswordModel) {
        return this.http.put(this.setNewPasswordUrl, model, false);
    }

    setNewAdminPassword(model: UserPasswordModel) {
        return this.http.put(this.setNewAdminPasswordUrl, model, true);
    }

    createPropertyLoginToken(propertyId: number) {
        return this.http.get(this.createPropertyLoginTokenUrl + '?propertyId=' + propertyId, false);
    }

    getForActivation() {
        return this.http.get(this.getForActivationUrl);
    }

    activateProperty(model: ActivationFlowModel) {
        return this.http.post(this.activatePropertyUrl, model);
    }

    getPropertyThumbnail() {
        return this.http.get(this.getPropertyThumbnailUrl);
    }

    getPropertyInvoiceLogoBase64() {
        return this.http.get(this.getPropertyInvoiceLogoBase64Url);
    }
}
